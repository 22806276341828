<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9766 0.648438C13.332 0.976562 13.332 1.55078 12.9766 1.87891L5.97656 8.87891C5.64844 9.23438 5.07422 9.23438 4.74609 8.87891L1.24609 5.37891C0.890625 5.05078 0.890625 4.47656 1.24609 4.14844C1.57422 3.79297 2.14844 3.79297 2.47656 4.14844L5.34766 7.01953L11.7461 0.648438C12.0742 0.292969 12.6484 0.292969 12.9766 0.648438Z"
      :fill="props.fill"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  fill: {
    type: String,
    required: false,
    default: 'white',
  },
});
</script>
